import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import axios from '../../../services/axios';

import Card from 'react-bootstrap/Card';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Table from 'react-bootstrap/Table';

// import Accordion from 'react-bootstrap/Accordion';
import AttendancePage from './AttendancePage.component';
import Leavetable from './Leavetable.component';
import TravelOrderPage from './TravelOrderPage.component';
import Compensatorytable from './compensatorytable.component';

export default function PageIndex(props) {
    const [listLeaveApplication, setListLeaveApplication] = useState([]);
    const [listTravelOrder, setListTravelOrder] = useState([]);
    const [listDailyTimeRecord, setDailyTimeRecord] = useState([]);
    const [listcompensatory, setlistcompensatory] = useState([]);
    const [loadingLeave, setLoadingLeave] = useState(true);
    const [loadingTO, setLoadingTO] = useState(true);
    const [loadingDTR, setLoadingDTR] = useState(true);
    const [loadingCoC, setLoadingCoC] = useState(true);
    // const [certifyLoad, setCertifyLoad] = useState(false);

    let approver = [];

    if (props.actor === 'office') {
        approver = props.user.assignatory.find(assigned => assigned.for === "off_att_app");
    }
    else {
        approver = props.user.assignatory.find(assigned => assigned.for === "pgo_app" || assigned.for === "pgo_app_heads");
    }


    const fetchLeaveApplications = async (officeId) => {
        try {
            setLoadingLeave(true);
            let officeIdString = [];

            if (props.actor === 'office') {
                officeIdString = officeId.join(',');
            }
            const response = await axios.post(`/api/office/list_leave_application`, { officeIdString: officeIdString, actor: props.actor });
            setListLeaveApplication(response.data.ApplicationLeave);
            setLoadingLeave(false);
        } catch (error) {
            console.error('Error fetching leave applications:', error);
        }
    };

    const fetchcompensatoryrequests = async (officeId) => {
        try {
            setLoadingCoC(true);
            let officeIdString = [];
            if (props.actor === 'office') {
                officeIdString = officeId.join(',');
            }
            const response = await axios.post(`/api/office/list_coc_requests`, { officeIdString: officeIdString, actor: props.actor });
            setlistcompensatory(response.data.CompensatoryRequests);
            setLoadingCoC(false);
        } catch (error) {
            console.error('Error fetching compensatory time-off credit requests:', error);
        }
    };

    const fetchTravelOrder = async (officeId) => {
        try {
            setLoadingTO(true);
            let officeIdString = [];
            if (props.actor === 'office') {
                officeIdString = officeId.join(',');
            }
            const response = await axios.post(`/api/office/list_travel_order`, {
                officeIdString: officeIdString,
                actor: props.actor,
            });
            setListTravelOrder(response.data.TravelOrder);
            setLoadingTO(false);
        } catch (error) {
            console.error('Error fetching travel order requests:', error);
        }
    };

    const fetchDailyTimeRecord = async (DTR_officeId) => {
        try {
            setLoadingDTR(true);
            let officeIdString = [];
            if (props.actor === 'office') {
                officeIdString = DTR_officeId.join(',');
            }


            const response = await axios.post(`/api/office/list_daily_time_record`, {
                officeIdString: officeIdString,
                actor: props.actor
            });
            setDailyTimeRecord(response.data.DailyTimeRecord);
            setLoadingDTR(false);
        } catch (error) {
            console.error('Error fetching leave applications:', error);
        }
    };

    useEffect(() => {
        if (approver) {
            fetchLeaveApplications(approver.office);
            fetchTravelOrder(approver.office);
            fetchDailyTimeRecord(approver.office);
            fetchcompensatoryrequests(approver.office);
        }
    }, [props.actor]);

    return (
        <div className="m-2">
            {
                !loadingDTR ? listDailyTimeRecord.length > 0 ?
                    (
                        <Card className="mt-2">
                            <Card.Header>
                                <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>DAILY TIME RECORDS</div>
                            </Card.Header>
                            <Card.Body style={{ padding: '1px' }}>
                                <AttendancePage
                                    listDailyTimeRecord={listDailyTimeRecord}
                                    setDailyTimeRecord={setDailyTimeRecord}
                                    actor={props.actor}
                                />
                            </Card.Body>
                        </Card>
                    )
                    : <Card className="mt-2">
                        <Card.Body>
                            <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>No Daily Time Records pending for approval.</div>
                        </Card.Body>
                    </Card>
                    : <Card className="mt-2">
                        <Card.Body>
                            <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>Loading Daily Time Records...</div>
                        </Card.Body>
                    </Card>
            }
            {
                !loadingLeave ? listLeaveApplication.length > 0 ?
                    (
                        <Card className="mt-2">
                            <Card.Header>
                                <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>LEAVE APPLICATIONS</div>
                            </Card.Header>
                            <Card.Body style={{ padding: '1px' }}>
                                <Leavetable
                                    listLeaveApplication={listLeaveApplication}
                                    setListLeaveApplication={setListLeaveApplication}
                                    actor={props.actor}
                                />
                            </Card.Body>
                        </Card>
                    )
                    : <Card className="mt-2">
                        <Card.Body>
                            <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>No Leave Applications pending for approval.</div>
                        </Card.Body>
                    </Card>
                    : <Card className="mt-2">
                        <Card.Body>
                            <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>Loading Leave Applications...</div>
                        </Card.Body>
                    </Card>
            }

            {
                !loadingTO ? listTravelOrder.length > 0 ?
                    (
                        <Card className="mt-2">
                            <Card.Header>
                                <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>TRAVEL ORDERS</div>
                            </Card.Header>
                            <Card.Body style={{ padding: '1px' }}>
                                <TravelOrderPage
                                    listTravelOrder={listTravelOrder}
                                    setListTravelOrder={setListTravelOrder}
                                    actor={props.actor}
                                />
                            </Card.Body>
                        </Card>
                    )
                    : <Card className="mt-2">
                        <Card.Body>
                            <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>No Travel Orders pending for approval.</div>
                        </Card.Body>
                    </Card>
                    : <Card className="mt-2">
                        <Card.Body>
                            <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>Loading Travel Orders...</div>
                        </Card.Body>
                    </Card>
            }

            {
                !loadingCoC ? listcompensatory.length > 0 ?
                    (
                        <Card className="mt-2">
                            <Card.Header>
                                <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>COMPENSATORY OVERTIME CREDIT (COC)</div>
                            </Card.Header>
                            <Card.Body style={{ padding: '1px' }}>
                                <Compensatorytable
                                    CompensatoryRequests={listcompensatory}
                                    setCompensatoryRequests={setlistcompensatory}
                                    actor={props.actor}
                                />
                            </Card.Body>
                        </Card>
                    )
                    : <Card className="mt-2">
                        <Card.Body>
                            <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>No Compensatory Overtime Credit pending for approval.</div>
                        </Card.Body>
                    </Card>
                    : <Card className="mt-2">
                        <Card.Body>
                            <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>Loading Compensatory Overtime Credit...</div>
                        </Card.Body>
                    </Card>
            }
        </div>
    )
}
