import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../services/axios';
import Swal from 'sweetalert2';
import { Calendar, toDateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

import EmployeeListSearchable from './EmployeeListSearchable.component';
import Comments from './Comments.component';

import CloseIcon from '@mui/icons-material/Close';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';

export default function TravelOrderModal(props) {
    const [selectedEmployee, setSelectedEmployee] = useState(props.selectedTO.participants);
    const [pgoToNo, setPgoToNo] = useState(props.selectedTO.pgo_to_no || "");
    const [event, setEvent] = useState(props.selectedTO.event);
    const [station, setStation] = useState(props.selectedTO.station);
    const [toDate, setToDate] = useState(props.selectedTO.dates.map(date => date));
    const [itinerary, setItinerary] = useState(props.selectedTO.itineraries.map(itin => itin));
    const [travel, setTravel] = useState(props.selectedTO.travel);
    const [charge, setCharge] = useState(props.selectedTO.charge);
    const [chargeSpecify, setChargeSpecify] = useState(props.selectedTO.charge_specify || "");
    const [offApp, setOffApp] = useState(props.selectedTO.office_approved);
    const [signed, setSigned] = useState(props.selectedTO.signed);
    const [reqAtt, setReqAtt] = useState(props.selectedTO.att_required);
    const [hasUpdate, setHasUpdate] = useState(false);
    const [updates, setUpdates] = useState({});

    //attachments
    const [delAttachments, setDelAttachments] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileChkFail, setFileChkFail] = useState([]);
    const fileInputRef = useRef();
    const handleFileChange = (event) => {
        let files = Array.from(event.target.files);

        const allowedFileType = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
        const maxSize = 2 * 1024 * 1024;

        if (files.length > 0) {
            let newSelectedFiles = [...selectedFiles];
            let newFileChkFail = [];
            files.map((file, key) => {
                let ext = file.name.split('.').pop();
                let size = file.size;
                let dupe = newSelectedFiles.filter(ff => { return ff.name === file.name }).length ? true : false;
                dupe = dupe ? true : props.selectedTO.travel_order_attachment.filter(ff => { return ff.document_name === file.name }).length ? true : false;
                if (allowedFileType.includes(ext) && size <= maxSize && !dupe) {
                    newSelectedFiles.push(file);
                } else {
                    newFileChkFail.push(`${file.name} : ${allowedFileType.includes(ext) ? "" : `${ext} file type is not allowed${(size <= maxSize || dupe) ? "" : ", "}`}${size <= maxSize ? "" : `file size is larger than 2mb${dupe ? ", " : ""}`}${dupe ? "duplicate file name was chosen, remove existing file or rename the current file" : ""}`);
                }
            })
            setSelectedFiles(newSelectedFiles);
            setFileChkFail(newFileChkFail);
        }
        event.target.value = "";
    }

    useEffect(() => {
        let changed = false;
        if (delAttachments.length !== 0) {
            changed = true;
        }
        let newUpdate = { ...updates };
        if (changed) {
            newUpdate["del_attachment"] = delAttachments;
        } else {
            delete newUpdate["del_attachment"];
        }
        setUpdates(newUpdate);

    }, [delAttachments]);

    useEffect(() => {
        let changed = false;
        if (selectedFiles.length > 0) {
            changed = true;
        }
        let newUpdate = { ...updates };
        if (changed) {
            newUpdate["to_attachment"] = selectedFiles.length;
        } else {
            delete newUpdate["to_attachment"];
        }
        setUpdates(newUpdate);
    }, [selectedFiles])

    const downloadDocument = async (fileLink) => {
        try {
            const response = await axios.get(fileLink);
            const fileUrl = response.data.fileUrl;
            window.open(fileUrl, '_blank');
        } catch (error) {
            console.error('Error opening file:', error);
        }
    }

    useEffect(() => {
        //console.log(updates);
        if (Object.keys(updates).length === 0) {
            setHasUpdate(false);
        } else {
            setHasUpdate(true);
        }
    }, [updates])

    const setCheckSelectedEmployee = (emp) => {
        if (selectedEmployee !== emp) {
            emp.map((em) => {
                if (em.ESTA === "JO" && !em.cert) {
                    em.cert = {
                        empId: em.id,
                        ESTA: em.ESTA,
                        name: em.name,
                        purpose: [""],
                        availability: false,
                        task: false,
                        presence: false,
                        others: false,
                        others_specify: ""
                    }
                }
            })
            setSelectedEmployee(emp);
        }
    }

    const handleChanges = (e, setVal, otherVal = null) => {
        let type = e.target.type;
        let val = type === "text" ? e.target.value : type === "radio" ? otherVal : parseInt(e.target.value);
        let name = e.target.name;
        setVal(val);
        let newUpdate = { ...updates };
        if (val !== props.selectedTO[name]) {
            newUpdate[name] = val;
        } else {
            delete newUpdate[name];
        }
        setUpdates(newUpdate);
    }

    const handleDateChanges = (dates) => {
        if(props.selectedTO.signed === 1 && (dates.length > props.selectedTO.dates.length)){
            dates = dates.slice(0, -1);
        }
        let datesArr = [];
        let changed = false;

        if (dates.length !== props.selectedTO.dates.length) {
            changed = true;
        } else {
            changed = false;
        }
        dates.forEach((date) => {
            let dateF = date.format("YYYY-MM-DD");
            datesArr.push(dateF);
            let exists = props.selectedTO.dates.find(date => date === dateF);
            if (!exists) {
                changed = true;
            }
        });
        setToDate(datesArr);
        let newUpdate = { ...updates };
        if (changed) {
            newUpdate["dates"] = datesArr;
        } else {
            delete newUpdate["dates"];
        }
        setUpdates(newUpdate);
    }

    // useEffect(() => {
    //     console.log(toDate);
    // }, [toDate]);

    useEffect(() => {
        let itineraryArr = [];
        let changed = false;
        if (itinerary.length !== props.selectedTO.itineraries.length) {
            changed = true;
        } else {
            changed = false;
        }

        itinerary.forEach((itin) => {
            itineraryArr.push(itin);
            let exists = props.selectedTO.itineraries.find(iti => iti.itinerary === itin);
            if (!exists) {
                changed = true;
            }
        });


        let newUpdate = { ...updates };
        if (changed) {
            newUpdate["itineraries"] = itineraryArr;
        } else {
            delete newUpdate["itineraries"];
        }
        setUpdates(newUpdate);
    }, [itinerary])

    useEffect(() => {
        let partArr = [];
        let changed = false;
        if (selectedEmployee.length !== props.selectedTO.participants.length) {
            changed = true;
        } else {
            changed = false;
        }

        selectedEmployee.forEach((part) => {
            partArr.push(part);
            let exists = props.selectedTO.participants.find(emp => emp === part);
            if (!exists) {
                changed = true;
            }
        });


        let newUpdate = { ...updates };
        if (changed) {
            newUpdate["participants"] = partArr;
        } else {
            delete newUpdate["participants"];
        }
        setUpdates(newUpdate);

    }, [selectedEmployee])

    const saveUpdates = async () => {
        if(!(validateFields())){
            const formData = new FormData();

            // let updateEmp = JSON.parse(JSON.stringify(updates));
            // updateEmp.participants[1].cert.id = 123;
            // setUpdates(updateEmp);

            formData.append('to_id', props.selectedTO.id);
            formData.append('updates', JSON.stringify(updates));
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('document[]', selectedFiles[i]);
            }
            Swal.fire({
                icon: "info",
                text: 'Saving...',
                showConfirmButton: false
            })
            await axios.post(`/api/travelorder/save`, formData).then(({ data, status }) => {
                if(status === 202){
                    Swal.update({
                        icon: "warning",
                        html: `<div>
                            <p>Unable to update, DTR period has already been approved for the following participants.</p>
                            <p>${Object.values(data.participants).join(", ")}</p>
                        </div>`
                    })
                }else{
                    Swal.update({
                        icon: "success",
                        text: data.message
                    })
                    props.closeFetchTO();
                }
                // Swal.update({
                //     icon: "success",
                //     text: data.message,
                //     showConfirmButton: true
                // })
                // props.closeFetchTO();
            }).catch((error) => {
                Swal.update({
                    text: error.response.data.message,
                    icon: "error"
                })
            })
        }
    }

    const validateFields = () => {
        let invalid = false;
        let requiredFields = document.getElementsByClassName("travel-order-view-modal-body-to")[0].querySelectorAll("[required]");
        requiredFields.forEach((el) => {
            if (el.value == 0 || el.value == "") {
                invalid = true;
                el.classList.add("is-invalid");
            } else {
                el.classList.remove("is-invalid");
            }
        })
        let participant = document.getElementById("participants-list");
        if (selectedEmployee.length === 0) {
            invalid = true;
            participant.classList.add("text-danger");
        } else {
            participant.classList.remove("text-danger");
        }

        let dates = document.getElementById("dates-list");
        if (toDate.length === 0) {
            invalid = true;
            dates.classList.add("text-danger");
        } else {
            dates.classList.remove("text-danger");
        }

        return invalid;
    }

    const cancelTOConfirm = async () => {
        Swal.fire({
            icon: "warning",
            text: "Are you sure you want to cancel this travel order? This action is permanent.",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            confirmButtonColor: "red"
        }).then((result) => {
            //console.log(result);
            if (result.isConfirmed) {
                cancelTO();
            }
        })
    }

    const cancelTO = async () => {
        const formData = new FormData();
        formData.append('to_id', props.selectedTO.id);
        //formData.append('user', props.user.unique);
        formData.append('updates', JSON.stringify({ status: 0 }));
        Swal.fire({
            icon: 'info',
            text: 'Saving changes...',
            showConfirmButton: false
        })
        await axios.post(`/api/travelorder/save`, formData).then(({ data }) => {
            Swal.update({
                icon: "success",
                text: data.message
            })
            props.closeFetchTO();
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
            })
        })
    }

    // useEffect(() => {
    //     console.log(updates.participants);
    // }, [updates])

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Travel Order</Modal.Title>
            </Modal.Header>
            <Modal.Body className="travel-order-view-modal-body">
                <Row className="travel-order-view-modal-body-to">
                    <Col className="travel-order-view-modal-body-to overflow-auto" sm={8}>
                        <Row className=" mt-1">
                            <Col className="text-left" sm={4}>
                                <Form.Label className="fw-bold">TRAVEL ORDER NO</Form.Label>
                            </Col>
                            <Col sm={8}>
                                {pgoToNo || ""}
                                {/* {props.editable.toNo && (props.selectedTO.signed !== 1) ? <Form.Control className="" name="pgo_to_no" as="input" placeholder="" value={pgoToNo} onChange={(event)=>{
                                    handleChanges(event, setPgoToNo);
                                }}/> : pgoToNo
                            } */}
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col className="text-left" sm={4}>
                                <Form.Label className="fw-bold">OFFICE</Form.Label>
                            </Col>
                            <Col sm={8}>
                                {props.selectedTO.office.offdesc || ""}
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col className="text-left" sm={4}>
                                <Form.Label className="fw-bold">EVENT/PURPOSE</Form.Label>
                            </Col>
                            <Col sm={8}>
                                {props.editable.event && props.selectedTO.office_approved !== 1 && props.selectedTO.status === 1 ? <Form.Control className="" name="event" as="input" placeholder="" value={event} required onChange={(event) => {
                                    handleChanges(event, setEvent);
                                }} /> : event
                                }
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col className="text-left" sm={4}>
                                <Form.Label className="fw-bold">ATTACHMENTS (If needed)</Form.Label>
                            </Col>
                            <Col className="" sm={8}>

                                {props.editable.attachment ? <Row className="mb-2">
                                    <Col>
                                        <Form.Control name="attachments" as="input" type="file" ref={fileInputRef} hidden onChange={handleFileChange} multiple={true} />
                                        <Button className="" variant="primary" size="sm" onClick={() => fileInputRef.current.click()}>
                                            Upload File
                                        </Button>
                                    </Col>
                                </Row> : ""}

                                <Row>
                                    <Col>
                                        {selectedFiles.map((file, key) => {
                                            return (
                                                <Row className="mb-1" key={key}>
                                                    <Col className="pe-1" col={10}>
                                                        <div className="card p-0 m-0">
                                                            <div className="card-body pt-1 pb-1">
                                                                <Form.Label className="fw-normal p-0 m-0">{file.name}</Form.Label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="p-0" col={2}>
                                                        {props.editable.attachment ? <Button className="" variant="danger" size="sm" title={`Remove "${file.name}"`} onClick={(e) => {
                                                            let newSelectedFiles = [...selectedFiles];
                                                            newSelectedFiles.splice(key, 1);
                                                            setSelectedFiles(newSelectedFiles);
                                                        }}>X</Button> : ""}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                        {props.selectedTO.travel_order_attachment.map((file, key) => {
                                            return (
                                                <Row className="mb-1" key={key}>
                                                    <Col className="pe-1" sm={10}>
                                                        <div className={`card border border-2 p-0 m-0 ${delAttachments.find(att => att === file.id) ? 'border-danger' : "border-success"}`} style={{ 'cursor': 'pointer' }} title={`Download "${file.document_name}"`} onClick={(e) => {
                                                            downloadDocument(file.link);
                                                            //downloadDocument2(file);
                                                        }}>
                                                            <div className="card-body pt-1 pb-1">
                                                                <Form.Label className="fw-normal p-0 m-0" style={{ 'cursor': 'pointer' }}>{file.document_name}</Form.Label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="p-0" sm={2}>
                                                        {props.editable.attachment ? delAttachments.find(att => att === file.id) ? <Button className="" variant="danger" size="sm" title={`Remove "${file.document_name}"`} onClick={(e) => {
                                                            let upDelAttachments = delAttachments.filter(att => att !== file.id);
                                                            setDelAttachments(upDelAttachments);
                                                        }}>Undo</Button> : <Button className="" variant="danger" size="sm" title={`Remove "${file.document_name}"`} onClick={(e) => {
                                                            let upDelAttachments = [...delAttachments];
                                                            upDelAttachments.push(file.id);
                                                            setDelAttachments(upDelAttachments);
                                                        }}>X</Button> : ""}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Col>
                                </Row>

                                {props.editable.attachment ? <Row>
                                    <Col>
                                        <ListGroup className="" variant="flush">
                                            {fileChkFail.map((file, key) => {
                                                return (
                                                    <ListGroup.Item className="p-1" key={key}>
                                                        <Form.Label className="fw-normal p-0 m-0 me-2 text-danger">{file}</Form.Label>
                                                    </ListGroup.Item>
                                                );
                                            })}
                                        </ListGroup>
                                    </Col>
                                </Row> : ""}

                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            {props.editable.participant && props.selectedTO.office_approved !== 1 && props.selectedTO.status === 1 ?
                                <>
                                    <Form.Label className="fw-bold">PARTICIPANTS</Form.Label>
                                    <Col className="mb-2" sm={12}>
                                        <EmployeeListSearchable
                                            selectedEmployee={selectedEmployee}
                                            setCheckSelectedEmployee={setCheckSelectedEmployee}
                                            maxHeight={"250px"}
                                            minHeight={"250px"}
                                            allowMultiple={true}
                                        />
                                        <div className="card p-0 mt-2">
                                            <div className="card-header m-0 p-0">
                                                <Row className="">
                                                    <Col className="text-center">
                                                        <Form.Label className="fw-bold" id="participants-list">Name of Participants</Form.Label>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="card-body p-0 m-0 emp-list-container">
                                                <ListGroup className="" variant="flush">
                                                    <div>
                                                        {selectedEmployee.map((employee, key) => {
                                                            let cert = employee.cert;
                                                            return <ListGroup.Item
                                                                className=""
                                                                key={key}
                                                            // onClick={() => {
                                                            //     setSelectedEmployee(selectedEmployee.filter(emp => emp !== employee));
                                                            // }}
                                                            // action
                                                            >
                                                                <Row>
                                                                    <Col>
                                                                        <b>{employee.name}</b>
                                                                        <br />
                                                                        <i>{`${employee.ESTA} - ${employee.position}`}</i>
                                                                    </Col>
                                                                    <Col sm="auto">
                                                                        <Tooltip title="Remove" arrow><IconButton
                                                                            onClick={() => {
                                                                                setSelectedEmployee(selectedEmployee.filter(emp => emp !== employee));
                                                                            }}
                                                                        ><CloseIcon fontSize="large" className="text-danger" /></IconButton></Tooltip>
                                                                    </Col>
                                                                </Row>
                                                                {cert ?
                                                                    <>
                                                                        <Row>
                                                                            <Col className="mb-2">
                                                                                <Form.Label className="fw-bold mb-2">Tasks
                                                                                </Form.Label>
                                                                                <Button className="ms-1 mb-2 fw-bold" variant="primary" onClick={(e) => {
                                                                                    let updateEmp = JSON.parse(JSON.stringify(selectedEmployee));
                                                                                    updateEmp[key].cert.purpose.push("");
                                                                                    setSelectedEmployee(updateEmp);
                                                                                }}>+</Button>
                                                                                {cert.purpose.map((item, purposeKey) => {
                                                                                    return (
                                                                                        <Row key={purposeKey}>
                                                                                            <Col sm={10}>
                                                                                                <Form.Control className="mb-1" name={`purpose${purposeKey}`} key={purposeKey} as="input" value={item} required onChange={(event) => {
                                                                                                    let updateEmp = JSON.parse(JSON.stringify(selectedEmployee));
                                                                                                    updateEmp[key].cert.purpose[purposeKey] = event.target.value;
                                                                                                    setSelectedEmployee(updateEmp);
                                                                                                }} />
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <Button variant="danger" onClick={(e) => {
                                                                                                    let updateEmp = JSON.parse(JSON.stringify(selectedEmployee));
                                                                                                    updateEmp[key].cert.purpose = updateEmp[key].cert.purpose.filter((purp, purpKey) => purpKey !== purposeKey)
                                                                                                    setSelectedEmployee(updateEmp);
                                                                                                }}>X</Button>
                                                                                            </Col>
                                                                                        </Row>)
                                                                                })}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <Form.Label>Reason:</Form.Label>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="auto">
                                                                                <input className="form-check-input ms-1" type="checkbox" name="availability" checked={cert.availability} onChange={(e) => {
                                                                                    let updateEmp = JSON.parse(JSON.stringify(selectedEmployee));
                                                                                    updateEmp[key].cert.availability = !cert.availability;
                                                                                    setSelectedEmployee(updateEmp);
                                                                                }} />
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Label className="m-auto">
                                                                                    Unavalability of regular/permanent official employee to perform the task.
                                                                                </Form.Label>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="auto">
                                                                                <input className="form-check-input ms-1" type="checkbox" name="task" checked={cert.task} onChange={(e) => {
                                                                                    let updateEmp = JSON.parse(JSON.stringify(selectedEmployee));
                                                                                    updateEmp[key].cert.task = !cert.task;
                                                                                    setSelectedEmployee(updateEmp);
                                                                                }} />
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Label className="m-auto">
                                                                                    Task is critical to the function of the Job Order/Contract of Service and the attachment of organizational goals and objectives.
                                                                                </Form.Label>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="auto">
                                                                                <input className="form-check-input ms-1" type="checkbox" name="presence" checked={cert.presence} onChange={(e) => {
                                                                                    let updateEmp = JSON.parse(JSON.stringify(selectedEmployee));
                                                                                    updateEmp[key].cert.presence = !cert.presence;
                                                                                    setSelectedEmployee(updateEmp);
                                                                                }} />
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Label className="m-auto">
                                                                                    Presence of the Job Order/Contract of Service is critical to the success of the activity to be attended.
                                                                                </Form.Label>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="auto">
                                                                                <input className="form-check-input ms-1" type="checkbox" name="others" checked={cert.others} onChange={(e) => {
                                                                                    let updateEmp = JSON.parse(JSON.stringify(selectedEmployee));
                                                                                    updateEmp[key].cert.others = !cert.others;
                                                                                    setSelectedEmployee(updateEmp);
                                                                                }} />
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Label>
                                                                                    Others, please specify:
                                                                                </Form.Label>
                                                                                <Form.Control name="others_specify" as="input" value={cert.others_specify} onChange={(e) => {
                                                                                    let updateEmp = JSON.parse(JSON.stringify(selectedEmployee));
                                                                                    updateEmp[key].cert.others_specify = e.target.value;
                                                                                    setSelectedEmployee(updateEmp);
                                                                                }} />
                                                                            </Col>
                                                                        </Row>
                                                                    </> : <></>}
                                                            </ListGroup.Item>
                                                        })}
                                                    </div>
                                                </ListGroup>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* <Col className="text-left" sm={6}>
                            <EmployeeListSearchable
                                selectedEmployee={selectedEmployee}
                                setCheckSelectedEmployee={setCheckSelectedEmployee}
                                minHeight={"430px"}
                                maxHeight={"430px"}
                                allowMultiple={true}
                            />
                        </Col>
                        <Col sm={6}>
                            <div className="card p-0" style={{minHeight: `${"430px"}`, maxHeight: `430px`}}>
                                <div className="card-header">
                                    <Row className="">
                                        <Col className="text-center">
                                            <Form.Label className="fw-bold">Name of Participants</Form.Label>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-body p-0 m-0">
                                    <ListGroup className="overflow-auto emp-list-container" variant="flush">
                                        <div>
                                            {selectedEmployee.map((employee, key) => (
                                            <ListGroup.Item
                                                className=""
                                                key={key}
                                                onClick={() => {
                                                    setSelectedEmployee(selectedEmployee.filter(emp => emp !== employee));
                                                }}
                                                action
                                            >
                                                {employee.name}
                                            </ListGroup.Item>
                                            ))}
                                        </div>
                                    </ListGroup>
                                </div>
                            </div>
                        </Col> */}
                                </> :
                                <>
                                    <Col className="" sm={12}>
                                        <Form.Label className="fw-bold">Name of Participants</Form.Label>
                                    </Col>
                                    <Col sm={12}>
                                        <ListGroup className="overflow-auto emp-list-container" variant="flush">
                                            <div>
                                                {selectedEmployee.map((employee, key) => {
                                                    let cert = employee.cert
                                                    return <ListGroup.Item
                                                        className=""
                                                        key={key}
                                                    >
                                                        <b>{employee.name}</b> - <i>{`${employee.ESTA} - ${employee.position}`}</i>
                                                        {
                                                            cert ? <Row className="">
                                                                <Col>
                                                                    {/* <Row>
                                                        <Col className=""><i>Certification</i></Col>
                                                    </Row> */}
                                                                    <Row className="">
                                                                        <Col className="">
                                                                            <Form.Label className="mb-0"><i>Tasks:</i></Form.Label>
                                                                            <ListGroup className="">
                                                                                {cert.purpose.map((item, key) =>
                                                                                    <React.Fragment key={key}>
                                                                                        <ListGroup.Item key={key}>
                                                                                            {item}
                                                                                        </ListGroup.Item>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </ListGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="">
                                                                        <Col className="">
                                                                            <Form.Label className="mb-0"><i>Reason:</i></Form.Label>
                                                                            <ListGroup className="">
                                                                                {cert.availability ? <ListGroup.Item>Unavalability of regular/permanent official employee to perform the task.</ListGroup.Item> : ""}
                                                                                {cert.task ? <ListGroup.Item>Task is critical to the function of the Job Order/Contract of Service and the attachment of organizational goals and objectives.</ListGroup.Item> : ""}
                                                                                {cert.presence ? <ListGroup.Item>Presence of the Job Order/Contract of Service is critical to the success of the activity to be attended.</ListGroup.Item> : ""}
                                                                                {cert.others ? <ListGroup.Item>{cert.others_specify}</ListGroup.Item> : ""}
                                                                            </ListGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row> : <></>
                                                        }
                                                    </ListGroup.Item>
                                                })}
                                            </div>
                                        </ListGroup>
                                    </Col>
                                </>}
                        </Row>

                        <hr />

                        <Row>
                            <Col className="text-left">
                                <Form.Label className="fw-bold" id="dates-list">TRAVEL DATES</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Calendar className="m-auto"
                                    multiple
                                    value={toDate}
                                    disabled={!(props.editable.travelDate && props.selectedTO.status !== 0)}
                                    onChange={handleDateChanges}
                                    plugins={[
                                        <DatePanel sort="date" />
                                    ]}
                                />
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col className="text-left" sm={4}>
                                <Form.Label className="fw-bold">PERMANENT STATION</Form.Label>
                            </Col>
                            <Col sm={8}>
                                {props.editable.station && props.selectedTO.office_approved !== 1 && props.selectedTO.status === 1 ? <Form.Control name="station" as="input" value={station} required onChange={(event) => {
                                    handleChanges(event, setStation);
                                }} /> : props.selectedTO.station}
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col className="text-left" sm={4}>
                                <Form.Label className="fw-bold">PLACE/S TO BE VISITED (Please specify Itinerary)</Form.Label>
                            </Col>
                            <Col sm={8}>
                                {props.editable.itinerary && props.selectedTO.office_approved !== 1 && props.selectedTO.status === 1 ? <Button className="ms-1 mb-2 fw-bold" variant="primary" onClick={(e) => {
                                    setItinerary([...itinerary, ""]);
                                }}>+</Button> : ""}
                                {itinerary.map((item, key) => {
                                    return props.editable.itinerary && props.selectedTO.office_approved !== 1 && props.selectedTO.status === 1 ? <Row key={key}>
                                        <Col sm={10}>
                                            <Form.Control className="mb-1" name={`place${item}`} key={key} as="input" value={item} required onChange={(event) => {
                                                let editItinerary = [...itinerary];
                                                editItinerary[key] = event.target.value;
                                                setItinerary(editItinerary);
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button variant="danger" onClick={(e) => {
                                                setItinerary(itinerary.filter((iti, itikey) => itikey !== key))
                                                //setChangesMade(true);
                                            }
                                            }>X</Button>
                                        </Col>
                                    </Row> : <React.Fragment key={key}>{key !== 0 ? <br /> : ""}{item}</React.Fragment>
                                })
                                }
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col className="text-left" sm={4}>
                                <Form.Label className="fw-bold">Attendance Requirement</Form.Label>
                            </Col>
                            <Col sm={8}>
                                {props.editable.attReq && props.selectedTO.office_approved !== 1 && props.selectedTO.status === 1 ? <div className="btn-group" role="group" aria-label="Attendance Requirement">
                                    <input type="radio" className="btn-check" name="att_required" id="attRequired1" autoComplete="off" checked={reqAtt === 1}
                                        onChange={(event) => handleChanges(event, setReqAtt, 1)}
                                    />
                                    <label className="btn btn-outline-primary toggle-button-first-item" htmlFor="attRequired1">Required</label>
                                    <input type="radio" className="btn-check" name="att_required" id="attRequired2" autoComplete="off" checked={reqAtt !== 1}
                                        onChange={(event) => handleChanges(event, setReqAtt, 0)}
                                    />
                                    <label className="btn btn-outline-primary" htmlFor="attRequired2">Not Required</label>
                                </div> :
                                    <label>
                                        {reqAtt === 1 ? "Required" : "Not Required"}
                                    </label>}
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col className="text-left" sm={4}>
                                <Form.Label className="fw-bold">Travel is on:</Form.Label>
                            </Col>
                            <Col sm={8}>
                                {props.editable.travel && props.selectedTO.office_approved !== 1 && props.selectedTO.status === 1 ? <Form.Select name="travel" value={travel} onChange={(event) => {
                                    handleChanges(event, setTravel);
                                }}>
                                    {[{ text: "Official Business", value: 0 }, { text: "Official Time", value: 1 }].map((opt, key) => (
                                        <option key={opt.value}
                                            value={opt.value}
                                        >{opt.text}</option>
                                    ))}
                                </Form.Select> : travel ? "Official Time" : "Official Business"}
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col className="text-left" sm={4}>
                                <Form.Label className="fw-bold">Expenses:</Form.Label>
                            </Col>
                            <Col sm={8}>
                                {props.editable.charge && props.selectedTO.office_approved !== 1 && props.selectedTO.status === 1 ? <><Form.Select className="mb-1" name="charge" value={charge} onChange={(event) => {
                                    handleChanges(event, setCharge);
                                }}>
                                    {[{ text: "Office Funds", value: 0 }, { text: "Others", value: 1 }].map((opt) => (
                                        <option key={opt.value}
                                            value={opt.value}
                                        >{opt.text}</option>
                                    ))}
                                </Form.Select>
                                    <Form.Control name="charge_specify" as="input" value={chargeSpecify} placeholder="Specify if needed" onChange={(event) => {
                                        handleChanges(event, setChargeSpecify);
                                    }} /></> : charge ? `Others - ${chargeSpecify}` : "Office Funds"}

                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col>
                                <Row>
                                    <Col className="text-left">
                                        <Form.Label className="fw-bold">Status</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="">
                                        <div className="progress mt-1 fw-bold" style={{ height: "30px" }}>
                                            {props.selectedTO.status ? <>
                                                <div className={`progress-bar ${props.selectedTO.office_approved !== 1 ? props.selectedTO.office_approved === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `50%` }}>Office</div>
                                                <div className={`progress-bar ${props.selectedTO.signed !== 1 ? props.selectedTO.signed === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `50%` }}>PGO</div>
                                            </> : <div className="progress-bar bg-danger" style={{ width: "100%" }}>Cancelled</div>}
                                        </div>
                                    </Col>
                                </Row>

                                {props.editable && props.editable.offApp && props.selectedTO.status === 1 ? <>
                                    <hr />
                                    <Row>
                                        <Col className="text-left" sm={4}>
                                            <Form.Label className="fw-bold">Office Approval</Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <div className="btn-group" role="group" aria-label="Office Approval">
                                                <input type="radio" className="btn-check" name="office_approved" id="offApproval1" autoComplete="off" checked={offApp === 1}
                                                    onChange={(event) => handleChanges(event, setOffApp, 1)}
                                                />
                                                <label className="btn btn-outline-success toggle-button-first-item" htmlFor="offApproval1">Approved</label>
                                                <input type="radio" className="btn-check" name="office_approved" id="offApproval2" autoComplete="off" checked={offApp === 0}
                                                    onChange={(event) => handleChanges(event, setOffApp, 0)}
                                                />
                                                <label className="btn btn-outline-danger" htmlFor="offApproval2">Disapproved</label>
                                                <input type="radio" className="btn-check" name="office_approved" id="offApproval3" autoComplete="off" checked={offApp === null}
                                                    onChange={(event) => handleChanges(event, setOffApp, null)}
                                                />
                                                <label className="btn btn-outline-warning" htmlFor="offApproval3">Pending</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </> : <></>}

                                {props.editable && props.editable.pgoApp && props.selectedTO.status === 1 ? <>
                                    <hr />
                                    <Row>
                                        <Col className="text-left" sm={4}>
                                            <Form.Label className="fw-bold">PGO Approval</Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <div className="btn-group" role="group" aria-label="Office Approval">
                                                <input type="radio" className="btn-check" name="signed" id="pgoApproval1" autoComplete="off" checked={signed === 1}
                                                    onChange={(event) => handleChanges(event, setSigned, 1)}
                                                />
                                                <label className="btn btn-outline-success toggle-button-first-item" htmlFor="pgoApproval1">Approved</label>
                                                <input type="radio" className="btn-check" name="signed" id="pgoApproval2" autoComplete="off" checked={signed === 0}
                                                    onChange={(event) => handleChanges(event, setSigned, 0)}
                                                />
                                                <label className="btn btn-outline-danger" htmlFor="pgoApproval2">Disapproved</label>
                                                <input type="radio" className="btn-check" name="signed" id="pgoApproval3" autoComplete="off" checked={signed === null}
                                                    onChange={(event) => handleChanges(event, setSigned, null)}
                                                />
                                                <label className="btn btn-outline-warning" htmlFor="pgoApproval3">Pending</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </> : <></>}

                            </Col>
                        </Row>

                    </Col>

                    <Col className="travel-order-view-modal-body-to" sm={4}>
                        <Comments
                            comments_id={props.selectedTO.id}
                            user={props.user}
                            module={'travel_order'}
                            postID={props.selectedTO.travel_order_post_id}
                        />
                    </Col>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                {props.selectedTO.status ? <Row className="me-auto">
                    <Col>
                        <Button variant="primary" onClick={(e) => {
                            saveUpdates();
                        }} disabled={!hasUpdate}>Update</Button>
                    </Col>
                    {props.editable.cancel && props.selectedTO.status ? <Col>
                        <Button variant="danger" onClick={(e) => {
                            cancelTOConfirm();
                        }}>Cancel</Button>
                    </Col> : ""}
                </Row> :
                    <Row className="me-auto">
                        <Col>
                            <Form.Label className="text-danger m-0 p-0">Travel Order has been cancelled.</Form.Label>
                        </Col>
                    </Row>
                }
            </Modal.Footer>
        </>
    )
}